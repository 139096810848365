import React from 'react'
import logo from '../assets/images/campaigns/pointer-io-logo.png'
import FreeAnalysisLandingPage from '../components/FreeAnalysisLandingPage'

const PointerIoPage = () => {
  return (
    <FreeAnalysisLandingPage
      header={
        <div
          style={{
            background: '#f9f9f9',
            marginBottom: '64px',
          }}
        >
          <div
            style={{
              margin: '0 auto',
              padding: '12px 0',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <img
              src={logo}
              alt="Pointer.io logo"
              style={{ maxWidth: '100%', height: '64px', display: 'block' }}
            />
          </div>
        </div>
      }
      campaign={{ value: 'POINTER_IO', audience: 'a Pointer.io reader' }}
    />
  )
}

export default PointerIoPage
